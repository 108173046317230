import { useState } from "react";
import MultiStepIndicator from "./shared/MultiStepIndicator";
import { StepModel } from "./shared/StepModel";

function Form() {
  const [currentStep, setCurrentStep] = useState(0);
  const stepModel = StepModel(currentStep, setCurrentStep);
  const StepFormComponent = stepModel.currentComponent();

  return (
    <div>
      <div
        className="absolute top-0 left-0 bottom-0 right-0 -z-10 bg-repeat-y bg-right-top"
        style={{ backgroundImage: "url('/background-image.png')" }}
      />
      <div className="mx-auto max-w-4xl px-6 pt-8">
        <img src="/logo.png" alt="ava care" width={300} />
        <MultiStepIndicator stepModel={stepModel} />
        <StepFormComponent stepModel={stepModel} />
      </div>
    </div>
  );
}

export default Form;
