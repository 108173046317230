function MultiStepIndicator({ stepModel }) {
  return (
    <div className="flex gap-4 text-sm pt-8 pb-10 select-none">
      {stepModel.list().map((step, index) => (
        <div
          key={index}
          onClick={() => stepModel.to(index)}
          className={
            " text-gray-400 " +
            (stepModel.isToStep(index) ? " cursor-pointer " : "") +
            (stepModel.isActiveStep(index)
              ? " text-pink-600 cursor-default"
              : "") +
            (stepModel.isPreviousStep(index)
              ? " text-gray-800 "
              : " cursor-default ")
          }
        >
          {step}
        </div>
      ))}
    </div>
  );
}

export default MultiStepIndicator;
