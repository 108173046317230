import { useNavigate } from "react-router-dom";

function Homepage() {
  const navigate = useNavigate();

  return (
    <div className="flex h-screen">
      <div className="m-auto max-w-xl px-6 pt-8 pb-8 bg-[#F3F2F9] flex flex-col rounded-3xl">
        <img
          className="mx-auto p-6"
          src="/logo.png"
          alt="Ava Care"
          width={300}
        />
        <div>
          En tant que vétérinaire, vous êtes sur le point de{" "}
          <b>soutenir la demande d'un de vos clients</b> dont l'animal nécessite
          des soins urgents, pour un financement via le programme{" "}
          <b>Ava Care</b>.
        </div>
        <div className="text-center p-6">
          <button
            onClick={() => navigate("/form")}
            type="button"
            className="rounded-full bg-[#20B6B2] hover:brightness-90 px-4 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <span className="flex gap-1">
              Continuer <img src="/next.png" alt="Continuer" width={20} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
