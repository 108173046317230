import { useState } from "react";
import { formDataModel } from "../shared/FormDataModel";

function S1AvaChart({ stepModel }) {
  const savedData = formDataModel.get(0);
  const [agreement, setAgreement] = useState(savedData.agreement);
  const [agreementError, setAgreementError] = useState(false);

  const save = () => {
    if (!agreement) return setAgreementError(true);
    formDataModel.save(0, { agreement });
    stepModel.next();
  };

  return (
    <div>
      <h2 className="text-3xl flex items-center">
        <div className="bg-[#E0A0C8] leading-9 text-xl rounded-3xl text-center text-white mr-4 w-12 h-12 rounded-full pt-1">
          1
        </div>
        Charte AVA Care
      </h2>

      <div className="py-4">
        Avant de poursuivre, il est impératif de vous engager envers la Charte
        AVA Care.<span className="text-pink-500">*</span>
        <div className="text-pink-500 text-xs">*étape obligatoire</div>
      </div>

      <div className="m-auto px-6 pt-8 pb-8 bg-[#20B6B2] text-sm text-white rounded-3xl">
        JE SOUHAITE ADHÉRER À AVA CARE POUR AIDER MES CLIENTS ET LEURS ANIMAUX :{" "}
        <b>JE SIGNE LA CHARTE</b>
        <br />
        <br />
        Je m'engage à "parrainer" les demandes de prises en charge de mes
        clients qui ont peu de ressources financières et se trouvent dans une
        situation d'urgence. Je consens à communiquer à l'association AVA
        l'ensemble du dossier médical de l'animal. <br />
        Je m'engage à constituer un dossier complet et à donner des informations
        justes à l'association AVA.
        <br />
        Je fournis à l'association AVA des photos et vidéos avant et après les
        soins apportés et autorise l'association à les utiliser dans le cadre de
        sa communication. <br />
        <br />
        J'autorise l'association AVA à communiquer sur la prise en charge
        réalisée dans mon établissement. <br />
        J'autorise l'association AVA à procéder à des vérifications, par quel
        que moyen que ce soit, quant à l'utilisation de l'aide perçue par mon
        établissement. <br />
        <b>
          J’atteste sur l’honneur l’exactitude des informations communiquées.
        </b>
        <div
          className={
            "mt-4 bg-[#E8E7F3] rounded-md text-gray-900 p-4 px-5 flex items-center inline-block " +
            (agreementError ? "border-solid border-2 border-red-500" : "")
          }
        >
          <input
            className="bg-white w-5 h-5 rounded-md cursor-pointer"
            type="checkbox"
            name="agree"
            id="agree"
            checked={agreement}
            value="Charte AVA Care acceptée"
            onChange={(event) => setAgreement(event.target.checked)}
          />
          <label className="ml-2 cursor-pointer w-full" htmlFor="agree">
            J’ai lu et j’accepte les conditions générales de la charte AVA Care{" "}
            <span className="text-pink-500">*</span>
          </label>{" "}
        </div>
      </div>

      <div className="pt-6 flex gap-2 mb-8">
        {stepModel.current() > 0 && (
          <button
            onClick={() => stepModel.previous()}
            type="button"
            className="rounded-full px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <img src="/previous.png" width={20} alt="Précédent" />
          </button>
        )}
        <button
          onClick={() => save()}
          type="button"
          className="rounded-full bg-[#20B6B2] hover:brightness-90 px-4 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <span className="flex gap-1">
            Valider <img src="/next.png" alt="Valider" width={20} />
          </span>
        </button>
      </div>
    </div>
  );
}

export default S1AvaChart;
