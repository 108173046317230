import { formDataModel } from "../shared/FormDataModel";
import { useState } from "react";

function S4Reason({ stepModel }) {
  const savedData = formDataModel.get(3);

  const [motivation, setMotivation] = useState(savedData.motivation);

  const [motivationError, setMotivationError] = useState(false);

  const save = () => {
    let error = false;

    if (!motivation) {
      setMotivationError(true);
      error = true;
    } else {
      setMotivationError(false);
    }

    if (error) return;

    formDataModel.save(3, {
      motivation,
    });

    stepModel.next();
  };
  return (
    <div>
      <h2 className="text-3xl flex items-center">
        <div className="bg-[#E0A0C8] leading-9 text-xl rounded-3xl text-center text-white mr-4 w-12 h-12 rounded-full pt-1">
          4
        </div>
        Motif de la demande de prise en charge
      </h2>

      <div className="py-4">
        Rédigez la demande circonstanciée de prise en charge pour les soins à
        apporter à l’animal concerné
        <span className="text-pink-500">*</span>
        <div className="text-pink-500 text-xs">*étape obligatoire</div>
      </div>

      <div className="m-auto py-6 pt-8 pb-8 text-sm grid grid-cols-3 gap-3">
        <div className="col-span-3">
          <label
            htmlFor="motivation"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Motif de la prise en charge<span className="text-pink-500">*</span>
          </label>
          <div className="mt-1.5">
            <textarea
              type="text"
              name="motivation"
              id="motivation"
              autoComplete="off"
              placeholder="Ecrire ici..."
              className={
                "min-h-20 block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" +
                (motivationError ? "border-solid border-2 border-red-500" : "")
              }
              value={motivation}
              onChange={(event) => setMotivation(event.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="pt-6 flex gap-2 mb-8">
        <button
          onClick={() => stepModel.previous()}
          type="button"
          className="rounded-full px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <img src="/previous.png" width={20} alt="Précédent" />
        </button>

        <button
          onClick={() => save()}
          type="button"
          className="rounded-full bg-[#20B6B2] hover:brightness-90 px-4 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <span className="flex gap-1">
            Valider <img src="/next.png" alt="Valider" width={20} />
          </span>
        </button>
      </div>
    </div>
  );
}

export default S4Reason;
