import { useNavigate } from "react-router-dom";
import { formDataModel } from "./form/shared/FormDataModel";

function Homepage() {
  const navigate = useNavigate();

  const newRequest = () => {
    formDataModel.reset();
    navigate("/");
  };

  return (
    <div className="flex h-screen">
      <div className="m-auto max-w-xl px-6 pt-8 pb-8 bg-[#F3F2F9] flex flex-col rounded-3xl">
        <img
          className="mx-auto p-6"
          src="/logo.png"
          alt="Ava Care"
          width={300}
        />
        <div className="text-center mt-4">
          Merci d'avoir soumis votre demande d'assistance pour l'animal "
          <b>{formDataModel.get(2)?.animalFirstname}</b>".
          <br />
          Nous allons l'analyser et revenir vers vous sous 24h.
        </div>
        <div className="text-center mt-8">
          <button
            onClick={newRequest}
            type="button"
            className="rounded-full bg-[#20B6B2] hover:brightness-90 px-4 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <span className="flex gap-1">Faire une nouvelle demande</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
