import { UploadDropzone } from "@bytescale/upload-widget-react";
import uploader from "../service/uploader";
import { formDataModel } from "../shared/FormDataModel";
import { useState } from "react";

function S5Proposal({ stepModel }) {
  const savedData = formDataModel.get(4);

  const [proposal, setProposal] = useState(savedData.proposal);

  const [proposalError, setProposalError] = useState(false);

  const save = () => {
    let error = false;

    if (!proposal) {
      setProposalError(true);
      error = true;
    } else {
      setProposalError(false);
    }

    if (error) return;

    formDataModel.save(4, {
      proposal,
    });

    stepModel.next();
  };

  return (
    <div>
      <h2 className="text-3xl flex items-center">
        <div className="bg-[#E0A0C8] leading-9 text-xl rounded-3xl text-center text-white mr-4 w-12 h-12 rounded-full pt-1">
          5
        </div>
        Devis
      </h2>

      <div className="py-4">
        Veuillez fournir le devis pour la prise en charge et les soins pour
        l’animal concerné
        <span className="text-pink-500">*</span>
        <div className="text-pink-500 text-xs">*étape obligatoire</div>
      </div>

      <div className="grid grid-cols-3">
        <div className="uploader">
          {" "}
          <UploadDropzone
            options={uploader.buildOptions(false)}
            onUpdate={({ uploadedFiles }) =>
              setProposal(uploadedFiles.map((x) => x.fileUrl)[0])
            }
            width="300px"
            height="300px"
          />
        </div>
        <div className="text-left col-span-2 mt-8">
          Merci d'uploader le devis dans l'espace de gauche.
          <br />
          Vous pourrez ensuite consulter votre document ci-dessous.
          <br />
          {proposal && (
            <a
              className="mt-4 block underline"
              target="_blank"
              rel="noreferrer"
              href={proposal}
            >
              Proposition importée
            </a>
          )}
          {proposalError && (
            <span className="text-red-500">
              Importez un document avant de continuer
            </span>
          )}
        </div>
      </div>

      <div className="pt-6 flex gap-2 mb-8">
        <button
          onClick={() => stepModel.previous()}
          type="button"
          className="rounded-full px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <img src="/previous.png" width={20} alt="Précédent" />
        </button>

        <button
          onClick={() => save()}
          type="button"
          className="rounded-full bg-[#20B6B2] hover:brightness-90 px-4 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <span className="flex gap-1">
            Valider <img src="/next.png" alt="Valider" width={20} />
          </span>
        </button>
      </div>
    </div>
  );
}

export default S5Proposal;
