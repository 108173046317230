import { formDataModel } from "../shared/FormDataModel";
import { useState } from "react";

function S2Clinic({ stepModel }) {
  const savedData = formDataModel.get(1);

  const [familyName, setFamilyName] = useState(savedData.familyName);
  const [givenName, setGivenName] = useState(savedData.givenName);
  const [address, setAddress] = useState(savedData.address);
  const [tel, setTel] = useState(savedData.tel);
  const [siren, setSiren] = useState(savedData.siren);
  const [email, setEmail] = useState(savedData.email);
  const [veterinaryOrderNumber, setVeterinaryOrderNumber] = useState(
    savedData.veterinaryOrderNumber,
  );

  const [familyNameError, setFamilyNameError] = useState(false);
  const [givenNameError, setGivenNameError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [telError, setTelError] = useState(false);
  const [sirenError, setSirenError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [veterinaryOrderNumberError, setVeterinaryOrderNumberError] =
    useState(false);

  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  function isValidPhoneNumber(email) {
    const emailRegex =
      /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
    return emailRegex.test(email);
  }

  const save = () => {
    let error = false;

    if (!familyName) {
      setFamilyNameError(true);
      error = true;
    } else {
      setFamilyNameError(false);
    }
    if (!givenName) {
      setGivenNameError(true);
      error = true;
    } else {
      setGivenNameError(false);
    }
    if (!address) {
      setAddressError(true);
      error = true;
    } else {
      setAddressError(false);
    }
    if (!siren) {
      setSirenError(true);
      error = true;
    } else {
      setSirenError(false);
    }
    if (!tel || !isValidPhoneNumber(tel)) {
      setTelError(true);
      error = true;
    } else {
      setTelError(false);
    }
    if (!email || !isValidEmail(email)) {
      setEmailError(true);
      error = true;
    } else {
      setEmailError(false);
    }
    if (!veterinaryOrderNumber) {
      setVeterinaryOrderNumberError(true);
      error = true;
    } else {
      setVeterinaryOrderNumberError(false);
    }

    if (error) return;

    formDataModel.save(1, {
      familyName,
      givenName,
      address,
      tel,
      email,
      siren,
      veterinaryOrderNumber,
    });

    stepModel.next();
  };
  return (
    <div>
      <h2 className="text-3xl flex items-center">
        <div className="bg-[#E0A0C8] leading-9 text-xl rounded-3xl text-center text-white mr-4 w-12 h-12 rounded-full pt-1">
          2
        </div>
        Information sur la clinique
      </h2>

      <div className="py-4">
        Fournir les détails spécifiques du vétérinaire impliqué
        <span className="text-pink-500">*</span>
        <div className="text-pink-500 text-xs">*étape obligatoire</div>
      </div>

      <div className="m-auto py-6 pt-8 pb-8 text-sm grid grid-cols-3 gap-3">
        <div>
          <label
            htmlFor="family-name"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Nom du vétérinaire<span className="text-pink-500">*</span>
          </label>
          <div className="mt-1.5">
            <input
              type="text"
              name="family-name"
              id="family-name"
              autoComplete="family-name"
              placeholder="Nom"
              className={
                "block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" +
                (familyNameError ? "border-solid border-2 border-red-500" : "")
              }
              value={familyName}
              onChange={(event) => setFamilyName(event.target.value)}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="given-name"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Prénom du vétérinaire<span className="text-pink-500">*</span>
          </label>
          <div className="mt-1.5">
            <input
              type="text"
              name="given-name"
              id="given-name"
              autoComplete="given-name"
              placeholder="Prénom"
              className={
                "block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" +
                (givenNameError ? "border-solid border-2 border-red-500" : "")
              }
              value={givenName}
              onChange={(event) => setGivenName(event.target.value)}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="veterinary-order-number"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            N° d’ordre vétérinaire<span className="text-pink-500">*</span>
          </label>
          <div className="mt-1.5">
            <input
              type="text"
              name="veterinary-order-number"
              id="veterinary-order-number"
              autoComplete="off"
              placeholder="Numéro"
              className={
                "block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" +
                (veterinaryOrderNumberError
                  ? "border-solid border-2 border-red-500"
                  : "")
              }
              value={veterinaryOrderNumber}
              onChange={(event) => setVeterinaryOrderNumber(event.target.value)}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="street-address"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Siren
            <span className="text-pink-500">*</span>
          </label>
          <div className="mt-1.5">
            <input
              type="number"
              name="siren"
              id="siren"
              autoComplete="off"
              placeholder="Siren"
              className={
                "block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" +
                (sirenError ? "border-solid border-2 border-red-500" : "")
              }
              value={siren}
              onChange={(event) => setSiren(event.target.value)}
            />
          </div>
        </div>
        <div className="col-span-2">
          <label
            htmlFor="street-address"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Adresse postale de l’établissement
            <span className="text-pink-500">*</span>
          </label>
          <div className="mt-1.5">
            <input
              type="text"
              name="street-address"
              id="street-address"
              autoComplete="street-address"
              placeholder="Adresse (n°, rue, code postal et ville)"
              className={
                "block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" +
                (addressError ? "border-solid border-2 border-red-500" : "")
              }
              value={address}
              onChange={(event) => setAddress(event.target.value)}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="tel"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Téléphone du vétérinaire<span className="text-pink-500">*</span>
          </label>
          <div className="mt-1.5">
            <input
              type="tel"
              name="tel"
              id="tel"
              autoComplete="tel"
              placeholder="Tél"
              className={
                "block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" +
                (telError ? "border-solid border-2 border-red-500" : "")
              }
              value={tel}
              onChange={(event) => setTel(event.target.value)}
            />
          </div>
        </div>
        <div className="col-span-2">
          <label
            htmlFor="email"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Adresse e-mail du vétérinaire
            <span className="text-pink-500">*</span>
          </label>
          <div className="mt-1.5">
            <input
              type="email"
              name="email"
              id="email"
              autoComplete="email"
              placeholder="E-mail"
              className={
                "block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" +
                (emailError ? "border-solid border-2 border-red-500" : "")
              }
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="pt-6 flex gap-2 mb-8">
        <button
          onClick={() => stepModel.previous()}
          type="button"
          className="rounded-full px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <img src="/previous.png" width={20} alt="Précédent" />
        </button>

        <button
          onClick={() => save()}
          type="button"
          className="rounded-full bg-[#20B6B2] hover:brightness-90 px-4 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <span className="flex gap-1">
            Valider <img src="/next.png" alt="Valider" width={20} />
          </span>
        </button>
      </div>
    </div>
  );
}

export default S2Clinic;
