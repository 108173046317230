class FormDataModel {
  stepValues = [];
  defaultStepValues = [
    {
      agreement: false,
    },
    {
      about: "clinic",
      familyName: "",
      givenName: "",
      veterinaryOrderNumber: "",
      address: "",
      tel: "",
      email: "",
      siren: "",
    },
    {
      about: "animal",
      animalFirstname: "",
      animalSpecie: "",
      animalSpecieOtherInfo: "",
      animalRace: "",
      animalBirthDate: "",
      animalGender: "",
      animalReproduction: "",
      animalId: "",
    },
    {
      about: "reason",
      motivation: "",
    },
    {
      about: "proposal",
      proposal: "",
    },
    {
      about: "exams",
      exams: [],
    },
    {
      about: "histories",
      histories: [],
    },
    {
      about: "medias",
      medias: [],
    },
    {
      about: "owner",
      familyName: "",
      givenName: "",
      address: "",
      tel: "",
      email: "",
    },
  ];

  constructor() {
    this.stepValues = cloneDeep(this.defaultStepValues);
  }

  save(step, value) {
    this.stepValues[step] = value;
  }

  get(step) {
    return this.stepValues[step];
  }

  reset() {
    this.stepValues = cloneDeep(this.defaultStepValues);
  }

  toJson() {
    const data = {
      charteAVACare: this.stepValues[0],
      veto: this.stepValues[1],
      animal: this.stepValues[2],
      owner: this.stepValues[8],
      reason: this.stepValues[3],
      proposal: encodeURI(this.stepValues[4].proposal),
      exams: this.stepValues[5].exams.map(encodeURI),
      histories: this.stepValues[6].histories.map(encodeURI),
      medias: this.stepValues[7].medias.map(encodeURI),
    };

    const text = `Charte validée: Oui

Vétérinaire
Nom: ${data.veto.familyName}
Prénom: ${data.veto.givenName}
Numéro d'ordre: ${data.veto.veterinaryOrderNumber}
Adresse: ${data.veto.address}
Tél: ${data.veto.tel}
Email: ${data.veto.email}
Siren: ${data.veto.siren}

Animal
Nom: ${data.animal.animalFirstname}
Espèce: ${data.animal.animalSpecie} ${
      data.animal.animalSpecieOtherInfo ? data.animal.animalSpecieOtherInfo : ""
    }
Race: ${data.animal.animalRace}
Anniversaire: ${data.animal.animalBirthDate}
Genre: ${data.animal.animalGender}
Status reproducteur: ${data.animal.animalReproduction}
Identifiant: ${data.animal.animalId}

Propriétaire
Nom: ${data.owner.familyName}
Prénom: ${data.owner.givenName}
Adresse: ${data.owner.address}
Tél: ${data.owner.tel}
Email: ${data.owner.email}

Motif: ${data.reason.motivation}

Proposition: 
  ${data.proposal}

Examens: 
  ${data.exams.join("\n  ")}

Historiques: 
  ${data.histories.join("\n  ")}

Médias: 
  ${data.medias.join("\n  ")}
    `;

    return { text, ...data };
  }
}

function cloneDeep(objectToClone) {
  // Avoid to embedded lodash just for it...
  return JSON.parse(JSON.stringify(objectToClone));
}

const formDataModel = new FormDataModel();
export { formDataModel };
