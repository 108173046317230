import { formDataModel } from "../shared/FormDataModel";
import { useState } from "react";

function S3Animal({ stepModel }) {
  const savedData = formDataModel.get(2);

  const [animalFirstname, setAnimalFirstname] = useState(
    savedData.animalFirstname,
  );
  const [animalSpecie, setAnimalSpecie] = useState(savedData.animalSpecie);
  const [animalSpecieOtherInfo, setAnimalSpecieOtherInfo] = useState(
    savedData.animalSpecieOtherInfo,
  );
  const [animalRace, setAnimalRace] = useState(savedData.animalRace);
  const [animalBirthDate, setAnimalBirthDate] = useState(
    savedData.animalBirthDate,
  );
  const [animalGender, setAnimalGender] = useState(savedData.animalGender);
  const [animalReproduction, setAnimalReproduction] = useState(
    savedData.animalReproduction,
  );
  const [animalId, setAnimalId] = useState(savedData.animalId);

  const [animalFirstnameError, setAnimalFirstnameError] = useState(false);
  const [animalSpecieOtherInfoError, setAnimalSpecieOtherInfoError] =
    useState(false);
  const [animalSpecieError, setAnimalSpecieError] = useState(false);
  const [animalRaceError, setAnimalRaceError] = useState(false);
  const [animalBirthDateError, setAnimalBirthDateError] = useState(false);
  const [animalGenderError, setAnimalGenderError] = useState(false);
  const [animalReproductionError, setAnimalReproductionError] = useState(false);
  const [animalIdError, setAnimalIdError] = useState(false);

  const save = () => {
    let error = false;

    if (!animalFirstname) {
      setAnimalFirstnameError(true);
      error = true;
    } else {
      setAnimalFirstnameError(false);
    }
    if (!animalSpecie) {
      setAnimalSpecieError(true);
      error = true;
    } else {
      setAnimalSpecieError(false);
      setAnimalSpecieOtherInfoError(false);
      if (animalSpecie === "Autre" && !animalSpecieOtherInfo) {
        setAnimalSpecieOtherInfoError(true);
        error = true;
      }
    }
    if (!animalRace) {
      setAnimalRaceError(true);
      error = true;
    } else {
      setAnimalRaceError(false);
    }
    if (!animalBirthDate) {
      setAnimalBirthDateError(true);
      error = true;
    } else {
      setAnimalBirthDateError(false);
    }
    if (!animalGender) {
      setAnimalGenderError(true);
      error = true;
    } else {
      setAnimalGenderError(false);
    }
    if (!animalReproduction) {
      setAnimalReproductionError(true);
      error = true;
    } else {
      setAnimalReproductionError(false);
    }
    if (!animalId) {
      setAnimalIdError(true);
      error = true;
    } else {
      setAnimalIdError(false);
    }

    if (error) return;

    formDataModel.save(2, {
      animalFirstname,
      animalSpecie,
      animalSpecieOtherInfo,
      animalRace,
      animalBirthDate,
      animalGender,
      animalReproduction,
      animalId,
    });

    stepModel.next();
  };
  return (
    <div>
      <h2 className="text-3xl flex items-center">
        <div className="bg-[#E0A0C8] leading-9 text-xl rounded-3xl text-center text-white mr-4 w-12 h-12 rounded-full pt-1">
          3
        </div>
        Information sur l'animal
      </h2>

      <div className="py-4">
        Fournir les détails spécifiques de l’animal concerné
        <span className="text-pink-500">*</span>
        <div className="text-pink-500 text-xs">*étape obligatoire</div>
      </div>
      <div className="m-auto py-6 pt-8 pb-8 text-sm grid grid-cols-3 gap-x-3 gap-y-3">
        <div className="col-span-3">
          <label
            className={
              "block text-sm font-semibold leading-6 text-gray-900" +
              (animalSpecieError ? " text-red-500 underline " : "")
            }
          >
            Espèce de l'animal<span className="text-pink-500">*</span>
          </label>
          <div className="flex flex-row gap-3 h-12">
            <div className="flex items-center">
              <div className="">
                <input
                  type="radio"
                  name="animal-specie"
                  id="dog"
                  autoComplete="off"
                  className="cursor-pointer inline-block px-3.5 py-2 text-gray-900 shadow-sm focus:ring-0 focus:ring-offset-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
                  value="Chien"
                  checked={animalSpecie === "Chien"}
                  onChange={(event) => setAnimalSpecie(event.target.value)}
                />
                <label className="pl-1 cursor-pointer" htmlFor="dog">
                  Chien
                </label>
              </div>
            </div>
            <div className="flex items-center">
              <div className="">
                <input
                  type="radio"
                  name="animal-specie"
                  id="cat"
                  autoComplete="off"
                  className="cursor-pointer inline-block px-3.5 py-2 text-gray-900 shadow-sm focus:ring-0 focus:ring-offset-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
                  value="Chat"
                  checked={animalSpecie === "Chat"}
                  onChange={(event) => setAnimalSpecie(event.target.value)}
                />
                <label className="pl-1 cursor-pointer" htmlFor="cat">
                  Chat
                </label>
              </div>
            </div>
            <div className="flex items-center">
              <div>
                <input
                  type="radio"
                  name="animal-specie"
                  id="other"
                  autoComplete="off"
                  className="cursor-pointer inline-block px-3.5 py-2 text-gray-900 shadow-sm focus:ring-0 focus:ring-offset-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
                  value="Autre"
                  checked={animalSpecie === "Autre"}
                  onChange={(event) => setAnimalSpecie(event.target.value)}
                />
                <label className="pl-1 cursor-pointer" htmlFor="other">
                  Autre
                </label>
              </div>
              {animalSpecie === "Autre" && (
                <input
                  type="text"
                  name="animal-specie-other-info"
                  id="animal-specie-other-info"
                  autoComplete="off"
                  placeholder="Précisez"
                  className={
                    "block rounded-md border-0 px-3.5 ml-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" +
                    (animalSpecieOtherInfoError
                      ? "border-solid border-2 border-red-500"
                      : "")
                  }
                  value={animalSpecieOtherInfo}
                  onChange={(event) =>
                    setAnimalSpecieOtherInfo(event.target.value)
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div>
          <label
            htmlFor="animal-firstname"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Prénom de l’animal<span className="text-pink-500">*</span>
          </label>
          <div className="mt-1.5">
            <input
              type="text"
              name="animal-firstname"
              id="animal-firstname"
              autoComplete="off"
              placeholder="Prénom"
              className={
                "block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" +
                (animalFirstnameError
                  ? "border-solid border-2 border-red-500"
                  : "")
              }
              value={animalFirstname}
              onChange={(event) => setAnimalFirstname(event.target.value)}
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="animal-race"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Race de l’animal<span className="text-pink-500">*</span>
          </label>
          <div className="mt-1.5">
            <input
              type="text"
              name="animal-race"
              id="animal-race"
              autoComplete="off"
              placeholder="Race"
              className={
                "block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" +
                (animalRaceError ? "border-solid border-2 border-red-500" : "")
              }
              value={animalRace}
              onChange={(event) => setAnimalRace(event.target.value)}
            />
          </div>
        </div>
        <div className="col-span-1"></div>
        <div>
          <label
            htmlFor="animal-birth-date"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Date de naissance
            <span className="text-pink-500">*</span>
          </label>
          <div className="mt-1.5">
            <input
              type="text"
              name="animal-birth-date"
              id="animal-birth-date"
              autoComplete="off"
              placeholder="JJ/MM/AAAA"
              className={
                "block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" +
                (animalBirthDateError
                  ? "border-solid border-2 border-red-500"
                  : "")
              }
              value={animalBirthDate}
              onChange={(event) => setAnimalBirthDate(event.target.value)}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="animal-id"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Numéro d’identification
            <span className="text-pink-500">*</span>
          </label>
          <div className="mt-1.5">
            <input
              type="text"
              name="animal-id"
              id="animal-id"
              autoComplete="off"
              placeholder="Numéro"
              className={
                "block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" +
                (animalIdError ? "border-solid border-2 border-red-500" : "")
              }
              value={animalId}
              onChange={(event) => setAnimalId(event.target.value)}
            />
          </div>
        </div>
        <div className="col-span-1"></div>
        <div>
          <label
            className={
              "block text-sm font-semibold leading-6 text-gray-900" +
              (animalGenderError ? " text-red-500 underline " : "")
            }
          >
            Genre<span className="text-pink-500">*</span>
          </label>
          <div className="mt-3 flex flex-row gap-3">
            <div className="flex items-center">
              <input
                type="radio"
                name="animal-gender"
                id="male"
                autoComplete="off"
                className="cursor-pointer inline-block px-3.5 py-2 text-gray-900 shadow-sm focus:ring-0 focus:ring-offset-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
                value="Mâle"
                checked={animalGender === "Mâle"}
                onChange={(event) => setAnimalGender(event.target.value)}
              />
              <label className="pl-1 cursor-pointer" htmlFor="male">
                Mâle
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                name="animal-gender"
                id="female"
                autoComplete="off"
                className="cursor-pointer inline-block px-3.5 py-2 text-gray-900 shadow-sm focus:ring-0 focus:ring-offset-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
                value="Femelle"
                checked={animalGender === "Femelle"}
                onChange={(event) => setAnimalGender(event.target.value)}
              />
              <label className="pl-1 cursor-pointer" htmlFor="female">
                Femelle
              </label>
            </div>
          </div>
        </div>

        <div>
          <label
            className={
              "block text-sm font-semibold leading-6 text-gray-900" +
              (animalReproductionError ? " text-red-500 underline " : "")
            }
          >
            Statut reproducteur<span className="text-pink-500">*</span>
          </label>
          <div className="mt-3 flex flex-row gap-3">
            <div className="flex items-center">
              <input
                type="radio"
                name="animal-reproduction"
                id="sterilized"
                autoComplete="off"
                className="cursor-pointer inline-block px-3.5 py-2 text-gray-900 shadow-sm focus:ring-0 focus:ring-offset-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
                value="Stérilisé"
                checked={animalReproduction === "Stérilisé"}
                onChange={(event) => setAnimalReproduction(event.target.value)}
              />
              <label className="pl-1 cursor-pointer" htmlFor="sterilized">
                Stérilisé
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                name="animal-reproduction"
                id="not-sterilized"
                autoComplete="off"
                className="cursor-pointer inline-block px-3.5 py-2 text-gray-900 shadow-sm focus:ring-0 focus:ring-offset-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
                value="Non stérilisé"
                checked={animalReproduction === "Non stérilisé"}
                onChange={(event) => setAnimalReproduction(event.target.value)}
              />
              <label className="pl-1 cursor-pointer" htmlFor="not-sterilized">
                Non stérilisé
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-6 flex gap-2 mb-8">
        <button
          onClick={() => stepModel.previous()}
          type="button"
          className="rounded-full px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <img src="/previous.png" width={20} alt="Précédent" />
        </button>

        <button
          onClick={() => save()}
          type="button"
          className="rounded-full bg-[#20B6B2] hover:brightness-90 px-4 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <span className="flex gap-1">
            Valider <img src="/next.png" alt="Valider" width={20} />
          </span>
        </button>
      </div>
    </div>
  );
}

export default S3Animal;
