import { formDataModel } from "../shared/FormDataModel";
import { useState } from "react";
import { UploadDropzone } from "@bytescale/upload-widget-react";
import uploader from "../service/uploader";

function S8Media({ stepModel }) {
  const savedData = formDataModel.get(7);

  const [medias, setMedias] = useState(savedData.medias);

  const [mediasError, setMediasError] = useState(false);

  const save = () => {
    let error = false;

    if (!medias || !medias.length) {
      setMediasError(true);
      error = true;
    } else {
      setMediasError(false);
    }

    if (error) return;

    formDataModel.save(7, {
      medias,
    });

    stepModel.next();
  };

  return (
    <div>
      <h2 className="text-3xl flex items-center">
        <div className="bg-[#E0A0C8] leading-9 text-xl rounded-3xl text-center text-white mr-4 w-12 h-12 rounded-full pt-1">
          8
        </div>
        Photos / vidéos de l’animal
      </h2>

      <div className="py-4">
        Ajoutez des photos et ou vidéos permettant d'apprécier la condition de
        l'animal
        <span className="text-pink-500">*</span>
        <div className="text-pink-500 text-xs">*étape obligatoire</div>
      </div>

      <div className="grid grid-cols-3">
        <div className="uploader">
          {" "}
          <UploadDropzone
            options={uploader.buildOptions(true)}
            onUpdate={({ uploadedFiles }) =>
              setMedias(
                [...medias, ...uploadedFiles.map((x) => x.fileUrl)].filter(
                  onlyUnique,
                ),
              )
            }
            width="300px"
            height="300px"
          />
        </div>
        <div className="text-left col-span-2 mt-8">
          Merci d'importer des photos, vidéos ou autres médias de l'animal dans
          l'espace de gauche.
          <br />
          Vous pourrez ensuite consulter votre document ci-dessous.
          <br />
          {medias && medias.length > 0 && (
            <div>
              {medias.map((media, index) => (
                <a
                  className="mt-4 block underline"
                  rel="noreferrer"
                  target="_blank"
                  href={media}
                >
                  Document importé {index + 1}
                </a>
              ))}
            </div>
          )}
          {mediasError && (
            <span className="text-red-500">
              Uploadez un ou plusieurs document avant de continuer
            </span>
          )}
        </div>
      </div>

      <div className="pt-6 flex gap-2 mb-8">
        <button
          onClick={() => stepModel.previous()}
          type="button"
          className="rounded-full px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <img src="/previous.png" width={20} alt="Précédent" />
        </button>

        <button
          onClick={() => save()}
          type="button"
          className="rounded-full bg-[#20B6B2] hover:brightness-90 px-4 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <span className="flex gap-1">
            Valider <img src="/next.png" alt="Valider" width={20} />
          </span>
        </button>
      </div>
    </div>
  );
}

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

export default S8Media;
