import { formDataModel } from "../shared/FormDataModel";
import axios from "axios";

class RequestSubmission {
  makeWebhook = "https://hook.eu1.make.com/hurskpuiwtele9jsjreorg437q57ijie";

  postRequest() {
    const data = formDataModel.toJson();

    const promise = axios.post(this.makeWebhook, data);
    promise
      .then(() => {
        console.info("Request submitted. Thank you.", data);
      })
      .catch(() => {
        console.error("Request error.", data);
      });
    return promise;
  }
}

const requestSubmission = new RequestSubmission();
export { requestSubmission };
