class Uploader {
  apiKey = "public_kW15bqBAGSbNMixo2hcSGYJacJ9c";

  buildOptions(multipleFiles = false) {
    return {
      apiKey: this.apiKey,
      maxFileCount: multipleFiles ? 10 : 1,
      showFinishButton: false,
      showRemoveButton: false,
      styles: {
        colors: {
          primary: "#377dff",
        },
        fontFamilies: {
          base: '"Satoshi-Regular", ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        },
      },
      editor: {
        images: {
          allowResizeOnMove: false,
          crop: false,
          preview: false,
        },
      },
    };
  }
}

const uploader = new Uploader();
export default uploader;
