import S1AvaChart from "../step/S1AvaChart";
import S3Animal from "../step/S3Animal";
import S4Reason from "../step/S4Reason";
import S5Proposal from "../step/S5Proposal";
import S6Exams from "../step/S6Exams";
import S7History from "../step/S7History";
import S8Media from "../step/S8Media";
import S9Owner from "../step/S9Owner";
import S2Clinic from "../step/S2Clinic";

export function StepModel(currentStep, setCurrentStep) {
  const steps = [
    "1. Charte Ava Care",
    "2. Clinique",
    "3. Animal",
    "4. Motif",
    "5. Devis",
    "6. Examens",
    "7. Historique",
    "8. Photos/vidéos",
    "9. Propriétaire",
  ];

  const stepComponents = [
    S1AvaChart,
    S2Clinic,
    S3Animal,
    S4Reason,
    S5Proposal,
    S6Exams,
    S7History,
    S8Media,
    S9Owner,
  ];

  return {
    current: () => {
      return currentStep;
    },

    currentComponent: () => {
      return stepComponents[currentStep];
    },

    list: () => {
      return steps;
    },

    next: () => {
      if (currentStep < steps.length - 1) setCurrentStep(currentStep + 1);
    },

    previous: () => {
      if (currentStep > 0) setCurrentStep(currentStep - 1);
    },

    isActiveStep: (step) => {
      return step === currentStep;
    },

    isPreviousStep: (step) => {
      return step < currentStep;
    },

    isToStep: (step) => {
      return step >= 0 && step < currentStep;
    },

    to: (step) => {
      if (step >= 0 && step < currentStep) setCurrentStep(step);
    },
  };
}
